import React from 'react'
import Forgotpasswordpage from '../components/ForgotPasswordPage/Forgotpasswordpage'

const Forgotpassword = () => {
  return (
    <div>
      <Forgotpasswordpage/>
    </div>
  )
}

export default Forgotpassword
