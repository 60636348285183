import React from 'react'
import RegisterPage from '../components/RegisterPage/RegisterPage'

const Register = () => {
  return (
    <div>
      <RegisterPage/>
    </div>
  )
}

export default Register
