import React from 'react'
import HomepageComponent from '../components/HomePage/HomepageComponent'

const Homepage = () => {
  return (
      <HomepageComponent/>
  )
}

export default Homepage
