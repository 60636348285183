import React from 'react'
import ContactPage from '../components/ContactPage/ContactPage'

const Contact = () => {
  return (
    <div>
      <ContactPage/>
    </div>
  )
}

export default Contact
