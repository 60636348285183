import React from 'react'
import TaskManagement from '../components/StudentDashboard/TaskManagement/TaskManagement'

const StudentTaskManagement = () => {
  return (
    <div>
      <TaskManagement/>
    </div>
  )
}

export default StudentTaskManagement
