import React from 'react'
import ProfTaskManagement from '../components/ProfessorDashboard/TaskManagement/ProfTaskManagement'


const ProfessorTaskManagement = () => {
  return (
    <div>
      <ProfTaskManagement/>
    </div>
  )
}

export default ProfessorTaskManagement
