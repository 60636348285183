import React from 'react'
import TaskDetailViewer from '../components/ProfessorDashboard/TaskManagement/TaskDetailViewer'

const TaskDetailed = () => {
  return (
    <div>
      <TaskDetailViewer/>
    </div>
  )
}

export default TaskDetailed
