import React from 'react'
import ReportViewer from '../components/ProfessorDashboard/ReportViewer/ReportViewer'

const ReportViewerPage = () => {
  return (
    <div>
      <ReportViewer/>
    </div>
  )
}

export default ReportViewerPage
