import React from 'react'
import SubmitReport from '../components/StudentDashboard/SubmitReport/SubmitReport'

const ReportSubmission = () => {
  return (
    <div>
      <SubmitReport/>
    </div>
  )
}

export default ReportSubmission
