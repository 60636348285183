import React from 'react'
import Chat from '../components/Chat/Chat'
const ChatPage = () => {
  return (
    <div>
      <Chat/>
    </div>
  )
}

export default ChatPage
