import React from 'react'
import StudentDashboard from '../components/StudentDashboard/StudentDashboard'

const StudentHome = () => {
  return (
    <div>
      <StudentDashboard/>
    </div>
  )
}

export default StudentHome
