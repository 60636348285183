import React from 'react'
import AboutPage from '../components/AboutPage/AboutPage'

const About = () => {
  return (
    <div>
      <AboutPage/>
    </div>
  )
}

export default About
