import React from 'react'
import Recommendations from '../components/ProfessorDashboard/Recommendations/Recommendations'

const ProfessorRecommendations = () => {
  return (
    <div>
      <Recommendations/>
    </div>
  )
}

export default ProfessorRecommendations
