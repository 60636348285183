import React from 'react'
import AICheck from '../components/ProfessorDashboard/AI Check/AICheck'

const AIReport = () => {
  return (
    <div>
      <AICheck/>
    </div>
  )
}

export default AIReport
