import React from 'react'
import ProfessorDashboard from '../components/ProfessorDashboard/ProfessorDashboard'

const ProfessorHome = () => {
  return (
    <div>
      <ProfessorDashboard/>
    </div>
  )
}

export default ProfessorHome
