import React from 'react'
import Reports from '../components/ProfessorDashboard/Reports/Reports'

const ProfessorReports = () => {
  return (
    <div>
      <Reports/>
    </div>
  )
}

export default ProfessorReports
