import React from 'react'
import Navbar from '../components/universalComponents/Navbar/Navbar'
import CalendarPage from '../components/StudentDashboard/Calendar/CalendarPage'

const Calendar = () => {
  return (
    <div>
      <CalendarPage/>
    </div>
  )
}

export default Calendar
